* {
  box-sizing: border-box;
}

#loading-spinner-wrapper{
  display:flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items:center;
  overflow:hidden;
}

#loading-spinner-wrapper p {
  color: #999999;  /* Mid-tone gray */
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  transform: translateY(-64px)
}

#loading-spinner {
  width:calc(2 * 200px);
  height: calc(2 * 200px);
}

.iNHqib,
.bdJqxB,


html,
body {
  display: block;
  width: 100%;
  margin: 0;
}

.App {
  display: flex;
  width: 100%;
  font-family: sans-serif;
  background: #f7f7f8;
}

form.Login {
  flex: 1 1 auto;
  display: block;
  min-width: 20rem;
  max-width: 50vw;
  margin: 0 auto;
  padding: 2rem 2rem 3rem;
  border: 1px solid #eaeaea;
  border-radius: 0.25rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1),
    0 0.6rem 0.8rem rgba(0, 0, 0, 0.1);
}

form.Login > * {
  display: block;
  width: 100%;
}

form.Login h1 {
  margin: 0 0 2rem;
  padding-right: 2rem;
  line-height: 1.4;
  font-size: 1.2rem;
  font-weight: normal;
}

form.Login h1 strong {
  display: block;
  color: #1054de;
  font-size: 1.5rem;
}

form.Login label {
  margin-bottom: 0.5rem;
  padding-left: 0.1rem;
  font-weight: bold;
  text-transform: capitalize;
  /*word-break: none;*/
}

form.Login label:after {
  content: ":";
}

form.Login input {
  margin-bottom: 1.7rem;
  padding: 0.6rem 0.7rem 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0.25rem;
  font-size: 1rem;
}

form.Login button {
  margin-top: 2rem;
  padding: 0.8rem 1rem;
  border: none;
  border-radius: 0.25rem;
  background: #1054de;
  cursor: pointer;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s;
}

form.Login button:hover {
  opacity: 0.8;
}
